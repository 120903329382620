"use client";

import { cn } from "@/Common/utils";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

export const TracingBeam = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const contentRef = useRef<HTMLDivElement>(null);
  const [svgHeight, setSvgHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        setSvgHeight(contentRef.current.offsetHeight);
      }
    };

    // Initial update
    updateHeight();

    // Update on resize
    window.addEventListener("resize", updateHeight);

    // Cleanup
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const y1 = useSpring(
    useTransform(scrollYProgress, [0, 0.8], [0, svgHeight]),
    {
      stiffness: 500,
      damping: 90,
    },
  );
  const y2 = useSpring(
    useTransform(scrollYProgress, [0, 1], [0, svgHeight - 200]),
    {
      stiffness: 500,
      damping: 90,
    },
  );

  const innerHeight = typeof window === "undefined" ? 0 : window.innerHeight;

  return (
    <motion.div
      suppressHydrationWarning
      ref={ref}
      className={cn("relative w-full", className)}
    >
      <div className="fixed left-0 top-0 z-50 h-full">
        <svg
          suppressHydrationWarning
          viewBox={`0 0 4 ${Math.max(svgHeight, innerHeight)}`}
          width="4"
          height={Math.max(svgHeight, innerHeight)}
          className="block"
          aria-hidden="true"
          preserveAspectRatio="none"
        >
          <motion.path
            d={`M 2 0 V ${svgHeight}`}
            fill="none"
            stroke="#9091A0"
            strokeOpacity="0.16"
            strokeWidth="4"
            transition={{
              duration: 10,
            }}
          ></motion.path>
          <motion.path
            d={`M 2 0 V ${svgHeight}`}
            fill="none"
            stroke="url(#gradient)"
            strokeWidth="4"
            className="motion-reduce:hidden"
            transition={{
              duration: 10,
            }}
          ></motion.path>
          <defs>
            <motion.linearGradient
              id="gradient"
              gradientUnits="userSpaceOnUse"
              x1="0"
              x2="0"
              y1={y1}
              y2={y2}
            >
              <stop stopColor="#18CCFC" stopOpacity="0"></stop>
              <stop offset="0.1" stopColor="#18CCFC"></stop>
              <stop offset="0.3" stopColor="#6344F5"></stop>
              <stop offset="1" stopColor="#AE48FF" stopOpacity="0"></stop>
            </motion.linearGradient>
          </defs>
        </svg>
      </div>
      <div ref={contentRef}>{children}</div>
    </motion.div>
  );
};
