"use client";

import { Button } from "@/Common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/Common/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/Common/ui/dropdown-menu";
import { ContactFormView } from "@/Form/views/ContactFormView";
import { useState } from "react";

interface ContactButtonProps {
  email: string;
  subject?: string;
  buttonText?: string;
  size?: "default" | "sm" | "lg" | "icon";
  variant?:
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "link";
}

export function ContactButton({
  email,
  subject,
  buttonText = "Contact Us",
  size = "default",
  variant = "default",
}: ContactButtonProps) {
  const [showDialog, setShowDialog] = useState(false);
  const mailtoUrl = `mailto:${email}${subject ? `?subject=${encodeURIComponent(subject)}` : ""}`;

  const copyEmail = async () => {
    await navigator.clipboard.writeText(email);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size={size} variant={variant}>
            {buttonText}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem asChild>
            <a href={mailtoUrl} className="cursor-pointer">
              <span className="icon-[lucide--mail] mr-2 size-4" />
              Open in Email Client
            </a>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowDialog(true)}>
            <span className="icon-[lucide--message-square] mr-2 size-4" />
            Use Contact Form
          </DropdownMenuItem>
          <DropdownMenuItem onClick={copyEmail}>
            <span className="icon-[lucide--copy] mr-2 size-4" />
            Copy Email Address
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Contact Us</DialogTitle>
            <DialogDescription>
              Send us a message and we&apos;ll get back to you as soon as
              possible.
            </DialogDescription>
          </DialogHeader>
          <ContactFormView onSuccess={() => setShowDialog(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
}
